<template>
  <div>
    <div class="d-flex align-items-center mb-2">
      <label for="conteudo">Conteúdo:</label>
      <button class="btn btn-primary ml-2" v-on:click="toggleCode">
        {{ showCode ? "Editor" : "Código fonte" }}
      </button>
    </div>

    <div v-if="!showCode">
      <vue-editor
        class="vue2-editor"
        v-model="item.conteudo"
        :editor-toolbar="customToolbar"
      ></vue-editor>
    </div>
    <div v-else>
      <div class="codemirror">
        <codemirror
          v-show="true"
          v-model="item.conteudo"
          :options="codeOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import { VueEditor } from "vue2-editor";
// theme css
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
import "codemirror/theme/zenburn.css";
// language js
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/display/autorefresh.js";
import "codemirror/mode/sql/sql.js";
// autoCloseTags
import "codemirror/addon/edit/closetag.js";

const initial = () => ({
  conteudo: "",
});

export default {
  name: "editor-conteudo",
  components: {
    codemirror,
    VueEditor,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      item: initial(),
      showCode: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video", "code"],
        ["clean"],
      ],
    };
  },
  computed: {
    codeOptions() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "htmlmixed",
        autoRefresh: false,
        lineWrapping: true,
        readOnly: false,

        theme: "monokai",
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true,
        },
      };
    },
  },
  methods: {
    toggleCode() {
      this.showCode = !this.showCode;
    },
  },
  mounted() {
    console.log(this.item);
  },
};
</script>

<style>
/* CSS personalizado pode ser adicionado aqui */
</style>
